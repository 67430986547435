import { render, staticRenderFns } from "./SubjectLayout.vue?vue&type=template&id=20b32afc&scoped=true&"
import script from "./SubjectLayout.vue?vue&type=script&lang=js&"
export * from "./SubjectLayout.vue?vue&type=script&lang=js&"
import style0 from "./SubjectLayout.vue?vue&type=style&index=0&id=20b32afc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20b32afc",
  null
  
)

export default component.exports